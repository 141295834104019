const Disclaimer = ({ closeDisclaimer }) => {
  return (
    <div id="disclaimer-popup" className="popup">
      <div className="disclaimer-content">
        <h2 id="disclaimer-title">Disclaimer</h2>
        <p id="disclaimer-text">
          Please be aware that the calculator cannot be 100 % accurate at
          predicting your risk of an Obstetric Anal Sphincter Injury. This
          calculator is recommended to use as a tool when planning mode of birth
          together with your obstetrician.
        </p>
        <div className="checkbox-label">
          <input type="checkbox" id="agree-checkbox" name="agree-checkbox" />
          <label id="agree-checkbox">I agree</label>
        </div>
        <br />
        <button id="disclaimer-button" onClick={closeDisclaimer}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
