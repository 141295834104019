import React, { useState } from "react";
import "../App.css";
import Slider from "./Slider";
import Radiobuttons from "./Radiobuttons";
import CalculateResultCathegoryA from "./FormulaA";
import Result from "./Result";
import Disclaimer from "./Disclaimer";
import ValueErrorPopup from "./ValueErrorPopup";
import RadioButtonError from "./RadioButtonError";

const FormCategoryA = () => {
  const [maternalAge, setMaternalAge] = useState(0);
  const [maternalHeight, setMaternalHeight] = useState(0);
  const [maternalWeight, setMaternalWeight] = useState(0);
  const [infantBirthWeight, setInfantBirthWeight] = useState(0);
  const [infantHeadCircumference, setInfantHeadCircumference] = useState(0);
  const [smokingPrePregnancy, setSmokingPrePregnancy] = useState(false);
  const [recurrentUrinaryTractInfection, setRecurrentUrinaryTractInfection] =
    useState(false);
  const [nonOecdCountryOfBirth, setNonOecdCountryOfBirth] = useState(false);
  const [vacuumDelivery, setVacuumDelivery] = useState(false);
  const [occiputPosterior, setOcciputPosterior] = useState(false);
  const [forcepsDelivery, setForcepsDelivery] = useState(false);
  const [medioLateralEpisiotomy, setMedioLateralEpisiotomy] = useState(false);
  const [spinalAnesthesia, setSpinalAnesthesia] = useState(false);
  const [shoulderDystocia, setShoulderDystocia] = useState(false);
  const [medianEpisiotomy, setMedianEpisiotomy] = useState(false);
  const [epiduralAnesthesia, setEpiduralAnesthesia] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [result, setResult] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showRadioButtonsError, setShowRadioButtonsError] = useState(false);

  const validateInputs = (
    maternalAge,
    maternalHeight,
    maternalWeight,
    infantBirthWeight,
    infantHeadCircumference
  ) => {
    if (
      maternalAge <= 50 &&
      maternalAge >= 14 &&
      maternalHeight <= 205 &&
      maternalHeight >= 100 &&
      maternalWeight <= 170 &&
      maternalWeight >= 30 &&
      infantBirthWeight >= 1000 &&
      infantBirthWeight <= 6500 &&
      infantHeadCircumference >= 25 &&
      infantHeadCircumference <= 50
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = validateInputs(
    maternalAge,
    maternalHeight,
    maternalWeight,
    infantBirthWeight,
    infantHeadCircumference
  );

  const validateRadioButtons = (
    smokingPrePregnancy,
    recurrentUrinaryTractInfection,
    nonOecdCountryOfBirth,
    vacuumDelivery,
    occiputPosterior,
    forcepsDelivery,
    medioLateralEpisiotomy,
    spinalAnesthesia,
    shoulderDystocia,
    medianEpisiotomy,
    epiduralAnesthesia
  ) => {
    if (
      smokingPrePregnancy === false ||
      recurrentUrinaryTractInfection === false ||
      nonOecdCountryOfBirth === false ||
      vacuumDelivery === false ||
      occiputPosterior === false ||
      forcepsDelivery === false ||
      medioLateralEpisiotomy === false ||
      spinalAnesthesia === false ||
      shoulderDystocia === false ||
      medianEpisiotomy === false ||
      epiduralAnesthesia === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const radioButtonsIsValid = validateRadioButtons(
    smokingPrePregnancy,
    recurrentUrinaryTractInfection,
    nonOecdCountryOfBirth,
    vacuumDelivery,
    occiputPosterior,
    forcepsDelivery,
    medioLateralEpisiotomy,
    spinalAnesthesia,
    shoulderDystocia,
    medianEpisiotomy,
    epiduralAnesthesia
  );

  const showRadioButtonsPopup = () => {
    setShowRadioButtonsError(true);
  };

  const closeRadioButtonsPopup = () => {
    setShowRadioButtonsError(false);
  };

  const showErrorPopup = () => {
    setShowError(true);
  };

  const closeErrorPopup = () => {
    setShowError(false);
  };

  const closeDisclaimer = () => {
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid) {
      showErrorPopup();
    } else if (!radioButtonsIsValid) {
      showRadioButtonsPopup();
    } else {
      if (disclaimerAccepted === false) {
        setShowDisclaimer(true);
      }
      let result = CalculateResultCathegoryA(
        maternalAge,
        maternalHeight,
        maternalWeight,
        infantBirthWeight,
        infantHeadCircumference,
        smokingPrePregnancy,
        recurrentUrinaryTractInfection,
        nonOecdCountryOfBirth,
        vacuumDelivery,
        occiputPosterior,
        forcepsDelivery,
        medioLateralEpisiotomy,
        spinalAnesthesia,
        shoulderDystocia,
        medianEpisiotomy,
        epiduralAnesthesia
      );
      setResult(result);
    }
  };

  const scrollToBottomSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = document.body.scrollHeight;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition + distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setMaternalAge(0);
    setMaternalHeight(0);
    setMaternalWeight(0);
    setInfantBirthWeight(0);
    setInfantHeadCircumference(0);
    setSmokingPrePregnancy(false);
    setRecurrentUrinaryTractInfection(false);
    setNonOecdCountryOfBirth(false);
    setVacuumDelivery(false);
    setOcciputPosterior(false);
    setForcepsDelivery(false);
    setMedioLateralEpisiotomy(false);
    setSpinalAnesthesia(false);
    setShoulderDystocia(false);
    setMedianEpisiotomy(false);
    setEpiduralAnesthesia(false);
    scrollToTopSmoothly(500);
    setTimeout(() => {
      setResult(null); // Set result to null after a delay
    }, 600); // Delay setResult(null) by 600 milliseconds (adjust as needed)
  };
  const scrollToTopSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = 0;
    const distance = startPosition - targetPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition - distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  return (
    <div className="form">
      <p id="calculator-title">CALCULATOR</p>
      <p id="under-title">Giving birth for the first time</p>
      <Slider
        inputName="Maternal age: "
        inputType=" years"
        minValue="14"
        maxValue="50"
        infoContent="Your age at the delivery"
        value={maternalAge}
        onChange={setMaternalAge}
      />
      <Slider
        inputName="Maternal height: "
        inputType=" cm"
        minValue="100"
        maxValue="205"
        infoContent="Your height in centimeters"
        value={maternalHeight}
        onChange={setMaternalHeight}
      />
      <Slider
        inputName="Maternal weight at first midwife appointment: "
        inputType=" kg"
        minValue="30"
        maxValue="170"
        infoContent="Your weight in kilograms at first midwife appointment"
        value={maternalWeight}
        onChange={setMaternalWeight}
      />
      <Slider
        inputName="Infant birth weight: "
        inputType=" g"
        minValue="1000"
        maxValue="6500"
        infoContent="Baby's birth weight in grams, estimated by ultrasound"
        value={infantBirthWeight}
        onChange={setInfantBirthWeight}
      />
      <Slider
        inputName="Infant head circumference: "
        inputType=" cm"
        minValue="25"
        maxValue="50"
        infoContent="Infant head circumference in centimeters, estimated by ultrasound"
        value={infantHeadCircumference}
        onChange={setInfantHeadCircumference}
      />
      <div className="radiobutton-container">
        <p id="yes-no">YES NO</p>
        <Radiobuttons
          inputName="Smoking pre-pregnancy"
          infoContent="Quit smoking >=3 months prior to pregnancy"
          groupName={"smoking-pre-pregnancy"}
          onChange={setSmokingPrePregnancy}
          value={smokingPrePregnancy}
        />
        <Radiobuttons
          inputName="Recurrent urinary tract infection"
          infoContent="Recurrent urinary tract infection before the pregnancy"
          groupName={"recurrent-urinary-tract-infection"}
          onChange={setRecurrentUrinaryTractInfection}
          value={recurrentUrinaryTractInfection}
        />
        <Radiobuttons
          inputName="Non OECD-country of birth"
          infoContent="Born outside of North America or Europe"
          groupName={"non-oecd-country-of-birth"}
          onChange={setNonOecdCountryOfBirth}
          value={nonOecdCountryOfBirth}
        />
        <span className="variable-information">
          The variables below are events that could occur during delivery.
          Please choose an option to see how your risk changes.
        </span>
        <Radiobuttons
          inputName="Vacuum delivery"
          infoContent="The use of a vacuum extractor during birth"
          groupName={"vacuum-delivery"}
          onChange={setVacuumDelivery}
          value={vacuumDelivery}
        />
        <Radiobuttons
          inputName="Occiput-posterior position"
          infoContent="Baby facing the mother's front"
          groupName={"occiput-posterior-position"}
          onChange={setOcciputPosterior}
          value={occiputPosterior}
        />
        <Radiobuttons
          inputName="Forceps delivery"
          infoContent="Baby delivered by forceps"
          groupName={"forceps-delivery"}
          onChange={setForcepsDelivery}
          value={forcepsDelivery}
        />
        <Radiobuttons
          inputName="Medio-lateral episiotomy"
          infoContent="An incision made slightly to one or the other side of your perineum (the tissue between the vaginal opening and the anus)"
          groupName={"medio-lateral-episiotomy"}
          onChange={setMedioLateralEpisiotomy}
          value={medioLateralEpisiotomy}
        />
        <Radiobuttons
          inputName="Spinal anesthesia"
          infoContent="An anesthetic injected into your lower back that lasts for 1-2 hours"
          groupName={"spinal-anesthesia"}
          onChange={setSpinalAnesthesia}
          value={spinalAnesthesia}
        />
        <Radiobuttons
          inputName="Shoulder dystocia"
          infoContent="One or both of your baby's shoulders get stuck inside your pelvis during birth"
          groupName={"shoulder-dystocia"}
          onChange={setShoulderDystocia}
          value={shoulderDystocia}
        />
        <Radiobuttons
          inputName="Median episiotomy"
          infoContent="A midline incision made through the perineum (the tissue between the vaginal opening and the anus)"
          groupName={"median-episiotomy"}
          onChange={setMedianEpisiotomy}
          value={medianEpisiotomy}
        />
        <Radiobuttons
          inputName="Epidural anesthesia"
          infoContent="A catheter inserted in your lower back to deliver anesthetics"
          groupName={"epidural-anesthesia"}
          onChange={setEpiduralAnesthesia}
          value={epiduralAnesthesia}
        />
      </div>
      <div className="form-buttons-div">
        <button id="calculate-button" onClick={handleSubmit}>
          CALCULATE
        </button>
        <button id="reset-button" onClick={handleReset}>
          RESET
        </button>
      </div>
      {showError && <ValueErrorPopup closeErrorPopup={closeErrorPopup} />}
      {showRadioButtonsError && (
        <RadioButtonError closeRadioButtonPopup={closeRadioButtonsPopup} />
      )}
      {showDisclaimer && (
        <Disclaimer
          closeDisclaimer={() => {
            closeDisclaimer(); // Close disclaimer
            scrollToBottomSmoothly(500); // Scroll to bottom after closing disclaimer
          }}
        />
      )}
      {disclaimerAccepted && result && (
        <Result
          result={result}
          compareBoxContent={
            "The average risk for women giving birth to their first child is 5,6 %."
          }
        />
      )}
    </div>
  );
};

export default FormCategoryA;
