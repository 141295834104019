import React, { useState, useEffect } from "react";
import "../App.css";
import Slider from "./Slider";
import Radiobuttons from "./Radiobuttons";
import CalculateResultCathegoryB from "./FormulaB";
import Result from "./Result";
import Disclaimer from "./Disclaimer";
import RadioButtonError from "./RadioButtonError";
import ValueErrorPopup from "./ValueErrorPopup";

const FormCategoryB = () => {
  const [maternalAge, setMaternalAge] = useState(0);
  const [maternalHeight, setMaternalHeight] = useState(0);
  const [maternalWeight, setMaternalWeight] = useState(0);
  const [weightGain, setWeightGain] = useState(0);
  const [infantBirthWeight, setInfantBirthWeight] = useState(0);
  const [smokingPrePregnancy, setSmokingPrePregnancy] = useState(false);
  const [vacuumDelivery, setVacuumDelivery] = useState(false);
  const [occiputPosterior, setOcciputPosterior] = useState(false);
  const [shoulderDystocia, setShoulderDystocia] = useState(false);
  const [medioLateralEpisiotomy, setMedioLateralEpisiotomy] = useState(false);
  const [spinalAnesthesia, setSpinalAnesthesia] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [result, setResult] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showRadioButtonsError, setShowRadioButtonsError] = useState(false);

  const validateInputs = (
    maternalAge,
    maternalHeight,
    maternalWeight,
    weightGain,
    infantBirthWeight
  ) => {
    if (
      maternalAge <= 50 &&
      maternalAge >= 14 &&
      maternalHeight <= 205 &&
      maternalHeight >= 100 &&
      maternalWeight <= 170 &&
      maternalWeight >= 30 &&
      weightGain >= 0 &&
      weightGain <= 100 &&
      infantBirthWeight >= 1000 &&
      infantBirthWeight <= 6500
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = validateInputs(
    maternalAge,
    maternalHeight,
    maternalWeight,
    weightGain,
    infantBirthWeight
  );

  const validateRadioButtons = (
    smokingPrePregnancy,
    vacuumDelivery,
    occiputPosterior,
    shoulderDystocia,
    medioLateralEpisiotomy,
    spinalAnesthesia
  ) => {
    if (
      smokingPrePregnancy === false ||
      vacuumDelivery === false ||
      occiputPosterior === false ||
      shoulderDystocia === false ||
      medioLateralEpisiotomy === false ||
      spinalAnesthesia === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const radioButtonsIsValid = validateRadioButtons(
    smokingPrePregnancy,
    vacuumDelivery,
    occiputPosterior,
    shoulderDystocia,
    medioLateralEpisiotomy,
    spinalAnesthesia
  );

  const showRadioButtonsPopup = () => {
    setShowRadioButtonsError(true);
  };

  const closeRadioButtonsPopup = () => {
    setShowRadioButtonsError(false);
  };

  const showErrorPopup = () => {
    setShowError(true);
  };

  const closeErrorPopup = () => {
    setShowError(false);
  };

  const closeDisclaimer = () => {
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid) {
      showErrorPopup();
    } else if (!radioButtonsIsValid) {
      showRadioButtonsPopup();
    } else {
      if (disclaimerAccepted === false) {
        setShowDisclaimer(true);
      }
      let result = CalculateResultCathegoryB(
        maternalAge,
        maternalHeight,
        maternalWeight,
        weightGain,
        infantBirthWeight,
        smokingPrePregnancy,
        vacuumDelivery,
        occiputPosterior,
        shoulderDystocia,
        medioLateralEpisiotomy,
        spinalAnesthesia
      );
      setResult(result);
    }
  };

  useEffect(() => {
    if (result !== null) {
      console.log("scroll");
      scrollToBottomSmoothly(500);
    }
  }, [result]);

  const scrollToBottomSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = document.body.scrollHeight;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition + distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setMaternalAge(0);
    setMaternalHeight(0);
    setMaternalWeight(0);
    setWeightGain(0);
    setInfantBirthWeight(0);
    setSmokingPrePregnancy(false);
    setVacuumDelivery(false);
    setOcciputPosterior(false);
    setShoulderDystocia(false);
    setMedioLateralEpisiotomy(false);
    setSpinalAnesthesia(false);
    scrollToTopSmoothly(500);
    setTimeout(() => {
      setResult(null); // Set result to null after a delay
    }, 600); // Delay setResult(null) by 600 milliseconds (adjust as needed)
  };
  const scrollToTopSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = 0;
    const distance = startPosition - targetPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition - distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  return (
    <div className="form">
      <p id="calculator-title">CALCULATOR</p>
      <p id="under-title">
        Giving birth for the second time, where first baby was delivered by
        cesarean
      </p>
      <Slider
        inputName="Maternal age: "
        inputType=" years"
        minValue="14"
        maxValue="50"
        infoContent="Your age at the delivery"
        value={maternalAge}
        onChange={setMaternalAge}
      />
      <Slider
        inputName="Maternal height: "
        inputType=" cm"
        minValue="100"
        maxValue="205"
        infoContent="Your height in centimeters"
        value={maternalHeight}
        onChange={setMaternalHeight}
      />
      <Slider
        inputName="Maternal weight at first midwife appointment: "
        inputType=" kg"
        minValue="30"
        maxValue="170"
        infoContent="Your weight in kilograms at first midwife appointment"
        value={maternalWeight}
        onChange={setMaternalWeight}
      />
      <Slider
        inputName="Weight gain since prior pregnancy: "
        inputType=" kg"
        minValue="0"
        maxValue="100"
        infoContent="Weight gain in kilograms from first midwife appointment in previous pregnancy, to the first midwife appointment in current pregnancy"
        value={weightGain}
        onChange={setWeightGain}
      />
      <Slider
        inputName="Infant birth weight: "
        inputType=" g"
        minValue="1000"
        maxValue="6500"
        infoContent="Baby's birth weight in grams, estimated by ultrasound"
        value={infantBirthWeight}
        onChange={setInfantBirthWeight}
      />
      <div className="radiobutton-container">
        <p id="yes-no">YES NO</p>
        <Radiobuttons
          inputName="Smoking pre-pregnancy"
          infoContent="Quit smoking >=3 months prior to pregnancy"
          groupName={"smoking-pre-pregnancy"}
          onChange={setSmokingPrePregnancy}
          value={smokingPrePregnancy}
        />
        <span className="variable-information">
          The variables below are events that could occur during delivery.
          Please choose an option to see how your risk changes.
        </span>
        <Radiobuttons
          inputName="Vacuum delivery"
          infoContent="The use of a vacuum extractor during birth"
          groupName={"vacuum-delivery"}
          onChange={setVacuumDelivery}
          value={vacuumDelivery}
        />
        <Radiobuttons
          inputName="Occiput-posterior position"
          infoContent="Baby facing the mother's front"
          groupName={"occiput-posterior-position"}
          onChange={setOcciputPosterior}
          value={occiputPosterior}
        />
        <Radiobuttons
          inputName="Medio-lateral episiotomy"
          infoContent="An incision made slightly to one or the other side of your perineum (the tissue between the vaginal opening and the anus)"
          groupName={"medio-lateral-episiotomy"}
          onChange={setMedioLateralEpisiotomy}
          value={medioLateralEpisiotomy}
        />
        <Radiobuttons
          inputName="Spinal anesthesia"
          infoContent="An anesthetic injected into your lower back that lasts for 1-2 hours"
          groupName={"spinal-anesthesia"}
          onChange={setSpinalAnesthesia}
          value={spinalAnesthesia}
        />
        <Radiobuttons
          inputName="Shoulder dystocia"
          infoContent="One or both of your baby's shoulders get stuck inside your pelvis during birth"
          groupName={"shoulder-dystocia"}
          onChange={setShoulderDystocia}
          value={shoulderDystocia}
        />
      </div>
      <div className="form-buttons-div">
        <button id="calculate-button" onClick={handleSubmit}>
          CALCULATE
        </button>
        <button id="reset-button" onClick={handleReset}>
          RESET
        </button>
      </div>
      {showError && <ValueErrorPopup closeErrorPopup={closeErrorPopup} />}
      {showRadioButtonsError && (
        <RadioButtonError closeRadioButtonPopup={closeRadioButtonsPopup} />
      )}
      {showDisclaimer && (
        <Disclaimer
          closeDisclaimer={() => {
            closeDisclaimer(); // Close disclaimer
            scrollToBottomSmoothly(500); // Scroll to bottom after closing disclaimer
          }}
        />
      )}
      {disclaimerAccepted && result && (
        <Result
          result={result}
          compareBoxContent={
            "The average risk for women giving birth to their second child where the first child was delivered by cesarean is 9 %."
          }
        />
      )}
    </div>
  );
};

export default FormCategoryB;
